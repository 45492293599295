<script setup lang="ts">
import type { HeaderStripWrapperProps } from './HeaderStripWrapper.props'

defineProps<HeaderStripWrapperProps>()

const stripsContainer = ref<HTMLElement>()

const { height } = useElementSize(stripsContainer)

watch(
  () => height.value,
  height => {
    if (document)
      document.documentElement.style.setProperty(
        '--header-strip-wrapper',
        `${height}px`
      )
  }
)
</script>

<template>
  <div
    id="strips-container"
    ref="stripsContainer"
    class="header-strip-wrapper flex flex-col"
    :class="{
      'is-hidden': isHidden,
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.header-strip-wrapper {
  margin-top: 0;
  transition: margin-top 0.3s ease-in-out;
  &.is-hidden {
    margin-top: calc(-1 * var(--header-strip-wrapper));
  }
}
</style>
