export async function useMarketingStrip(deliveryKey: string) {
  const localePath = useLocalePath()
  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: deliveryKey,
  }

  const { data } = await useFetch('/api/cms/getMarketingStrip', {
    query,
  })

  return {
    data,
  }
}
